// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-button-mdx": () => import("./../../../src/pages/components/button.mdx" /* webpackChunkName: "component---src-pages-components-button-mdx" */),
  "component---src-pages-components-card-mdx": () => import("./../../../src/pages/components/card.mdx" /* webpackChunkName: "component---src-pages-components-card-mdx" */),
  "component---src-pages-components-drawer-js": () => import("./../../../src/pages/components/drawer.js" /* webpackChunkName: "component---src-pages-components-drawer-js" */),
  "component---src-pages-components-input-mdx": () => import("./../../../src/pages/components/input.mdx" /* webpackChunkName: "component---src-pages-components-input-mdx" */),
  "component---src-pages-components-modal-js": () => import("./../../../src/pages/components/modal.js" /* webpackChunkName: "component---src-pages-components-modal-js" */),
  "component---src-pages-components-navbar-mdx": () => import("./../../../src/pages/components/navbar.mdx" /* webpackChunkName: "component---src-pages-components-navbar-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */)
}

